 <template>
  <div class="coin container">
    <div class="title">{{ $t('app.coin.title') }}</div>
    <div class="box">
      <div class="balance">{{ $t('com.balance') }}: {{ balance | numFilter6 }}</div>
      <div class="item border">
        <div class="input">
          <el-input-number ref="inputNumber"  v-model="UNumber"  :min="min" :max="1800" step-strictly></el-input-number>
        </div>
      </div>
      <div class="icon-change"></div>
      <div class="item border">
        <div class="number">{{ coinNumber }} <span class="icon"></span></div>
      </div>
      <div class="btn-buy" v-if="allowance <= 0" @click="approve">{{ $t('com.approve', ['USDT']) }}</div>
      <div class="btn-buy" v-else @click="handleBuy">{{ $t('app.coin.buy') }}</div>
      <div class="tips">
        {{ $t('app.coin.buy') }}
        {{ total - limit }} / {{ total }} USDT
      </div>
      <div class="reward">
        <span>
          {{ $t('app.coin.purchased') }}: 
          {{ (total - limit) / min * 2 | numFilter4 }} WF
        </span>
        <div class="btn">{{ $t('com.reward') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import AutoInput from '../../components/autoInput.vue'
import { ethers } from 'ethers'
import Community from '@/contracts/Community.json'
import ERC20 from '../../contracts/ERC20.json'
import { initSigner } from '../../utlis/index'

export default {
  data () {
    return {
      contracts: {},
      address: '',
      referee: '',
      min: 300,           // 步进值
      UNumber: 300,      // USDT数量
      total: 5190,      // 总可购买量
      limit: 0,         // 剩余可购买量
      balance: 0,
      allowance: 0
    }
  },
  watch:{
    UNumber(newVal){
      if(newVal == 299) this.UNumber = 300;
      else if(newVal == 1799) this.UNumber = 300;
      else if(newVal == 3299) this.UNumber = 1800;
      else if(newVal > 300 && newVal <= 1800) this.UNumber = 1800;
      // else if(newVal > 1800) this.UNumber = 3300;
      this.$refs.inputNumber.currentValue = this.UNumber;
      // else this.UNumber = newVal;
    }
  },
  computed: {
    coinNumber () {
      let num = '-'
      if (this.UNumber) {
        num = Number(this.UNumber) / 150
      }
      return num
    }
  },
  mounted () {
    this.referee = this.$route.query.referee || '0x0000000000000000000000000000000000000000'
    this.init()
  },
  beforeDestroy () {
    clearInterval(this.initTimer)
  },
  methods: {
    // inputNumber(newVal,a){
    //   console.log(newVal,a)
    //   // if(newVal == 1799) this.UNumber = 1800;
    //   /*else */
    // },
    async init () {
      let { address, provider, signer } = await initSigner()
      this.address = address

      this.contracts.Community = new ethers.Contract(this.$common.Community, Community, provider).connect(signer)
      this.contracts.USDT = new ethers.Contract(this.$common.USDT, ERC20, provider).connect(signer)

      this.getData()
      window.contracts = this.contracts;
      clearInterval(this.initTimer)
      this.initTimer = setInterval(() => {
        this.getData()
      }, 20000);
    },
    async getData () {
      if (!this.address) return false
      // 最小限购量(每笔购买单位)
      // this.contracts.Community.limit().then(res => {
      //   let num = ethers.utils.formatUnits(res, 18)
      //   this.min = Number(num)
      //   this.UNumber = Number(num)
      // })
      // 获取剩余可购买量
      this.contracts.Community.getLimitByAddress(this.address).then(res => {
        let num = ethers.utils.formatUnits(res, 18)
        this.limit = num
      })
      // USDT余额授权额度
      this.contracts.USDT.balanceOf(this.address).then(res => {
        let num = ethers.utils.formatUnits(res, 18)
        this.balance = num
      })
      this.contracts.USDT.allowance(this.address, this.$common.Community).then(res => {
        let num = ethers.utils.formatUnits(res, 18)
        this.allowance = num
      })
    },
    handleBuy () {
      if (!this.UNumber || this.UNumber == '-') return false
      else if (Number(this.limit) <= 0 || Number(this.limit) < this.UNumber)
        // return this.$message.warning('当前USDT剩余可购买量为' + this.limit)
        return this.$message.warning(this.$t('msg.balanceInsufficient', [this.limit]))
      let num = ethers.utils.parseUnits(String(this.UNumber))
      this.contracts.Community.swap(this.referee, num).then(async (result) => {
        await result.wait()
        this.$message.success(this.$t('msg.purchaseSucc'))
        this.getData()
      }).catch((err) => {
        console.log(err);
        this.$message.error(this.$t('msg.PurchaseFailed'))
      });
    },
    // 授权USDT
    approve () {
      const max = ethers.constants.MaxUint256
      this.contracts.USDT.approve(this.$common.Community, max).then(async (result) => {
        await result.wait()
        this.$message.success(this.$t('msg.approveSucc'))
        this.getData()
      }).catch((err) => {
        console.log(err);
        this.$message.error(this.$t('msg.approveFaile'))
      });
    },
  },
  components: {}
}
</script>

<style lang="scss" scoped>
.coin {
  padding: 1.4rem 0.44rem 0.4rem;
  .title {
    width: 5.74rem;
    height: 1.66rem;
    line-height: 1.28rem;
    font-size: 0.36rem;
    text-align: center;
    font-weight: bold;
    margin: 0 auto;
    background: url('../../assets/img/web-title-bg.png') left top / 100% 100% no-repeat;
  }
  // .border {
  //   border: 1px solid #BBB;
  // }
  .box {
    padding: 0.6rem;
    box-sizing: border-box;
    text-align: center;
    background: url('../../assets/img/coin-bg.png') left top / 100% 100% no-repeat;
  }
  .balance {
    text-align: left;
    margin-bottom: 0.2rem;
  }
  .item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    // padding: 0.22rem 0.48rem;
    .pre, .next {
      width: 0.4rem;
      height: 0.4rem;
      line-height: 0.4rem;
      text-align: center;
      background-color: pink;
      cursor: pointer;
    }
    .input {
      display: flex;
      align-items: center;
      font-size: 00.32rem;
      width: 100%;
      // input {
      //   max-width: 100px;
      //   text-align: center;
      //   font-size: 00.32rem;
      // }
      .icon {
        position: absolute;
      }
      &::v-deep .el-input-number {
        width: 100%;
        // line-height: 0.84rem;
        .el-input-number__decrease, .el-input-number__increase {
          width: 0.82rem;
          height: 0.82rem;
          line-height: 0.82rem;
          font-size: 0.3rem;
          color: #FFF;
          background-color: #1f104b;
          // border: 1px solid #DCDFE6;
          // box-sizing: border-box;
        }
        .el-input__inner {
          height: 0.84rem;
          line-height: 0.84rem;
          padding-right: 1rem;
          font-size: 0.3rem;
          box-sizing: border-box;
          background-color: rgba(85,114,241,0.8);
          border-color: #45BBF3;
          color: #FFF;
        }
        .el-input {
          &::after {
            content: ' ';
            position: absolute;
            right: 1.5rem;
            top: 50%;
            transform: translateY(-50%);
            width: 0.5rem;
            height: 0.5rem;
            background: url('../../assets/img/USDT.png') left top / 100% 100% no-repeat;
          }
        }
      }
      
    }
    .number {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.3rem;
      width: 5.3rem;
      height: 0.84rem;
      line-height: 0.84rem;
      background-color: rgba(85,114,241,0.8);
      box-sizing: border-box;
      border: 1px solid #45BBF3;
      text-align: center;
      .icon {
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        margin-left: 0.2rem;
        background: url('../../assets/img/WF.png') left top / 100% 100% no-repeat;
      }
    }
  }
  .icon-change {
    width: 0.3rem;
    height: 0.57rem;
    margin: 0.2rem auto;
    background: url('../../assets/img/change-icon.png') left top / 100% 100% no-repeat;
  }
  .btn-buy {
    font-size: 0.3rem;
    display: inline-block;
    width: 5.3rem;
    height: 0.84rem;
    line-height: 0.84rem;
    background-color: rgba(178, 133, 237, 0.8);
    box-sizing: border-box;
    border: 1px solid #45BBF3;
    text-align: center;
    position: relative;
    margin: 0.58rem auto 0;
    cursor: pointer;
    // &::after {
    //   content: ' ';
    //   position: absolute;
    //   left: 0.08rem;
    //   top: 0.08rem;
    //   width: 100%;
    //   height: 100%;
    //   border: 1px solid #B285ED;
    // }
  }
  .tips {
    text-align: left;
    margin-top: 0.36rem;
    font-size: 0.24rem;
  }
  .reward {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.3rem;
    .btn {
      width: 1rem;
      height: 0.5rem;
      line-height: 0.5rem;
      text-align: center;
      background: gray;
      border-radius: 00.1rem;
    }
  }
}
</style>
